import { Table, Carousel, Image, Typography } from "antd";
const { Text, Link } = Typography;

const TableComponent = ({ records, tableLoading }) => {
  // Собирать уникальные ключи из всех записей
  let allKeys = Array.from(new Set(records.flatMap(Object.keys)));
  // Initialize an ordered list of keys
  let orderedKeys = [];

  // Conditionally add 'images' and 'title' if they exist
  if (allKeys.includes("images")) {
    orderedKeys.push("images");
  }
  if (allKeys.includes("title")) {
    orderedKeys.push("title");
  }

  // Add remaining keys, excluding 'images' and 'title'
  orderedKeys = orderedKeys.concat(
    allKeys.filter((key) => key !== "images" && key !== "title")
  );

  const columns = orderedKeys.map((key) => ({
    title: key.charAt(0).toUpperCase() + key.slice(1),
    dataIndex: key,
    key: key,
    width: 200,
    ellipsis: true,
    render: (text, record) => {
      if (key === "title") {
        return (
          <Link href={record.url} target="_blank">
            {text}
          </Link>
        );
      }
      if (record.url.includes("otodom.pl/") && key === "images") {
        return (
          <Image.PreviewGroup>
            <Carousel autoplay arrows>
              {record.images &&
                record.images.map((image, index) => (
                  <div key={index}>
                    <Image
                      src={image.small}
                      alt="zdjęcie"
                      style={{
                        width: "100%",
                        height: "auto",
                        aspectRatio: "16/9",
                        objectFit: "cover",
                      }}
                      preview={{
                        src: image.large,
                      }}
                    />
                  </div>
                ))}
            </Carousel>
          </Image.PreviewGroup>
        );
      } else if (record.url.includes("otomoto.pl/") && key === "images") {
        return (
          <Image.PreviewGroup>
            <Carousel autoplay arrows>
              {record.images &&
                record.images.thumbnails.map((image, index) => (
                  <div key={index}>
                    <Image
                      src={image.url}
                      alt="zdjęcie"
                      style={{
                        width: 150,
                        height: "auto",
                        aspectRatio: "16/9",
                        objectFit: "cover",
                      }}
                      preview={{
                        src: record.images.photos[index].url,
                      }}
                    />
                  </div>
                ))}
            </Carousel>
          </Image.PreviewGroup>
        );
      }

      return text;
    },
  }));

  return (
    <Table
      dataSource={records}
      loading={tableLoading}
      size="small"
      pagination={{ pageSize: 50 }}
      scroll={{
        x: 2000,
      }}
      columns={columns}
    />
  );
};

export default TableComponent;
